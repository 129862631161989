<template>
  <div class="dashboard">
    dashboard
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  created () {
    this.handdleMsg()
  },
  methods: {
    handdleMsg (msg) {
      const that = this
      console.log(that.global.ws)
      if (that.global.ws && that.global.ws.readyState === 1) {
        console.log('发送信息', msg)
        that.global.ws.send(msg)
      }
      that.global.ws.onmessage = function (res) {
        console.log('收到服务器内容', res)
      }
    }
  }
}
</script>

<style scoped>

</style>
